import type {FunctionComponent} from 'preact';
import type {PropsWithChildren} from 'preact/compat';
import {useCallback, useEffect, useMemo, useState} from 'preact/hooks';
import {v4 as uuidv4} from 'uuid';

import {useBugsnag} from '~/foundation/Bugsnag/hooks';
import {useRootProvider} from '~/foundation/RootProvider/hooks';
import {useCart} from '~/hooks/useCart';
import type {Cart} from '~/types/cart';
import {inferBackgroundColor} from '~/utils/colors';
import {constructLink} from '~/utils/constructLink';
import {AbstractShopJSError} from '~/utils/errors';
import {getHostname} from '~/utils/hostname';
import {isoWindow} from '~/utils/window';

import {usePaymentTermsMonorail} from '../monorail';
import type {
  CartShopifyMetadata,
  InstallmentsBannerContent,
  InstallmentsModalVariant,
  LegacyCartShopifyMetadata,
  LegacyProductShopifyMetadata,
  ProductShopifyMetadata,
  ShopifyPaymentTermsProps,
  VariantModalDetails,
} from '../types';
import {
  getPrequalModalType,
  getSamplePlansModalType,
  getSplitPayModalType,
} from '../utils/getModalType';
import {convertPriceToNumber} from '../utils/price';

import {useCartObserver} from './hooks/useCartObserver';
import {initialContext, InstallmentsContext} from './InstallmentsContext';
import {
  getAvailableLoanTypes,
  getFinancingTermForCart,
  getLowestLoanTypes,
  getMinIneligibleMessageType,
  getNumberOfPaymentTermsForPDPVariant,
  getProductQuantityFromDOM,
  getSellerIdInNumber,
  isLegacyShopifyMetadata,
  isShopifyMetadata,
  buildInstallmentPlans,
  calculatePricePerTerm,
  extractSnakeCasedMetadata,
  getCartPermalink,
  getFormattedSamplePlans,
  updatePDPVariant,
  fetchAuthToken,
} from './utils';

export type InstallmentsProviderProps = PropsWithChildren &
  Omit<ShopifyPaymentTermsProps, 'element'>;

export const InstallmentsProvider: FunctionComponent<
  InstallmentsProviderProps
> = ({children, shopifyMeta, variantId: variantIdProp}) => {
  const {notify, leaveBreadcrumb} = useBugsnag();
  const {
    trackElementImpression,
    trackInstallmentsBannerImpression,
    trackInstallmentsBannerPrequalInteraction,
    trackInvalidInstallmentBannerMetadata,
    trackInstallmentsPrequalPopupPageImpression,
    trackModalOpened,
  } = usePaymentTermsMonorail();
  const {element, instanceId} = useRootProvider();

  const [checkoutUrl, setCheckoutUrl] = useState<string | null>(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isLegacyBanner, setIsLegacyBanner] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalToken, setModalToken] = useState(uuidv4());
  const [fullPrice, setFullPrice] = useState<string>('');
  const [eligible, setEligible] = useState<boolean>(false);
  const [variantId, setVariantId] = useState(variantIdProp);
  const [authorizationToken, setAuthorizationToken] = useState<string>('');
  const [variantInfo, setVariantInfo] = useState<
    VariantModalDetails | undefined
  >();

  const hostname = getHostname();

  const [cartDetails, setCartDetails] = useState<Cart | undefined>();

  const {getCart} = useCart();

  // If the variant-id attribute is changed, we need to update the variant-id state
  useEffect(() => {
    if (variantId !== variantIdProp) {
      setVariantId(variantIdProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantIdProp]);

  const elementName = useMemo(() => {
    return isLegacyBanner ? 'shop-pay-banner' : 'shop-pay-installments-banner';
  }, [isLegacyBanner]);

  const cartPermalink = useMemo(
    () => getCartPermalink({cartDetails, modalToken, variantInfo}),
    [cartDetails, modalToken, variantInfo],
  );

  const parsedShopifyMeta = useMemo(() => {
    if (!shopifyMeta) {
      return;
    }

    let parsed;
    try {
      parsed = JSON.parse(shopifyMeta);
    } catch (error) {
      /**
       * Merchants may render the installments component using a custom theme edit,
       * and pass in bad data. We want to log this issue, but not fire Bugsnag
       * notifications.
       */
      // eslint-disable-next-line no-console
      console.warn?.('[Shop Pay Installments] Error parsing metadata', error);
      return;
    }

    try {
      if (
        isShopifyMetadata(parsed, () =>
          trackInvalidInstallmentBannerMetadata(parsed.type, shopifyMeta),
        )
      ) {
        setDataLoaded(true);
        setIsLegacyBanner(false);
        return parsed;
      }

      if (
        isLegacyShopifyMetadata(parsed, () =>
          trackInvalidInstallmentBannerMetadata(parsed.type, shopifyMeta),
        )
      ) {
        setDataLoaded(true);
        setIsLegacyBanner(true);
        return parsed;
      }
    } catch (error) {
      if (
        error instanceof TypeError &&
        error.message.match(
          "Failed to construct 'HTMLElement': This instance is already constructed",
        )
      ) {
        // eslint-disable-next-line no-console
        console.error(error);
        return;
      }

      if (error instanceof Error) {
        notify(error, {
          metadata: {
            component: {
              name: 'shop-pay-installments-banner',
              shopifyMeta,
              variantId,
            },
          },
        } as any);
      }
    }
  }, [notify, shopifyMeta, trackInvalidInstallmentBannerMetadata, variantId]);

  const metaType = useMemo(
    () => parsedShopifyMeta?.type ?? initialContext.metaType,
    [parsedShopifyMeta?.type],
  );

  const lowestLoanTypes = useMemo(
    () =>
      parsedShopifyMeta && 'financing_plans' in parsedShopifyMeta
        ? getLowestLoanTypes(parsedShopifyMeta.financing_plans)
        : [],
    [parsedShopifyMeta],
  );

  const minIneligibleMessageType = useMemo(
    () => getMinIneligibleMessageType(lowestLoanTypes),
    [lowestLoanTypes],
  );

  const backgroundColor = useMemo(
    () => inferBackgroundColor(element),
    [element],
  );

  const {
    canShowSamplePlanModalContent,
    financingTermForBanner,
    hasZeroInterestLoanType,
    installmentPlans,
    installmentsBuyerPrequalificationEnabled,
    isEligibleForPrequalification,
    isInAdaptiveRangeWithoutZeroInterest,
    loanTypes,
    maxPrice,
    minPrice,
    numberOfPaymentTerms,
    pricePerTerm,
    sellerId,
    variantAvailable,
  } = useMemo(() => {
    if (!parsedShopifyMeta) {
      return initialContext;
    }

    const baseMeta = extractSnakeCasedMetadata({
      extract: [
        'installments_buyer_prequalification_enabled',
        'max_price',
        'min_price',
        'seller_id',
      ],
      meta: parsedShopifyMeta,
    });

    let {
      eligible,
      financingTermForBanner,
      fullPrice,
      installmentPlans,
      loanTypes,
      numberOfPaymentTerms,
      pricePerTerm,
      variantAvailable,
    } = initialContext;

    const isProductBanner = metaType === 'product';

    if (isProductBanner && isLegacyBanner && variantId) {
      const metadata = parsedShopifyMeta as LegacyProductShopifyMetadata;
      const variants = metadata.variants;

      const variant = variants.find(
        (variant) => Number(variant.id) === Number(variantId),
      );

      eligible = variant ? variant.eligible : false;
      loanTypes = variant ? (variant.available_loan_types ?? []) : [];
      numberOfPaymentTerms = metadata.number_of_payment_terms;
      pricePerTerm = variant ? variant.price : '';
    }

    if (isProductBanner && !isLegacyBanner && variantId) {
      const metadata = parsedShopifyMeta as ProductShopifyMetadata;
      const updated = updatePDPVariant({
        financingPlans: metadata.financing_plans,
        variants: metadata.variants,
        variantId,
      });

      eligible = updated.eligible;
      financingTermForBanner = updated.financingTermForBanner;
      fullPrice = updated.fullPrice;
      loanTypes = updated.loanTypes;
      numberOfPaymentTerms = getNumberOfPaymentTermsForPDPVariant({
        variantId: Number(variantId),
        variants: metadata.variants,
      });
      pricePerTerm = updated.pricePerTerm;
      variantAvailable = updated.variantAvailable;
    }

    if (metaType === 'cart' && isLegacyBanner) {
      const metadata = parsedShopifyMeta as LegacyCartShopifyMetadata;
      eligible = metadata.eligible;
      loanTypes = metadata.available_loan_types ?? [];
      numberOfPaymentTerms = metadata.number_of_payment_terms;
      pricePerTerm = metadata.price;
    }

    if ((metaType === 'cart' || metaType === 'checkout') && !isLegacyBanner) {
      const metadata = parsedShopifyMeta as CartShopifyMetadata;

      fullPrice = metadata.full_price;
      const financingPlans = metadata.financing_plans;

      const availableLoanTypes = getAvailableLoanTypes({
        fullPrice,
        financingPlans,
      });

      const termForBanner = getFinancingTermForCart({
        financingPlans,
        price: fullPrice,
      });

      eligible = metadata.eligible;
      financingTermForBanner = termForBanner ?? null;
      loanTypes = availableLoanTypes ?? [];
      numberOfPaymentTerms = termForBanner
        ? termForBanner.installments_count
        : metadata.number_of_payment_terms;
      pricePerTerm = termForBanner
        ? calculatePricePerTerm({
            price: convertPriceToNumber(metadata.full_price),
            term: termForBanner,
          })
        : metadata.price_per_term;
    }

    const hasZeroInterestLoanType = loanTypes.includes('zero_percent');
    const isInAdaptiveRangeWithoutZeroInterest =
      eligible &&
      !hasZeroInterestLoanType &&
      loanTypes.length === 2 &&
      loanTypes.includes('split_pay') &&
      loanTypes.includes('interest');

    installmentPlans =
      parsedShopifyMeta && 'financing_plans' in parsedShopifyMeta
        ? buildInstallmentPlans({
            totalPrice: fullPrice,
            financingPlans: parsedShopifyMeta.financing_plans,
            isInAdaptiveRangeWithoutZeroInterest,
          })
        : [];

    const isEligibleForPrequalification = Boolean(
      eligible && baseMeta.installmentsBuyerPrequalificationEnabled,
    );

    const onlyHasInterestLoanType =
      loanTypes.length === 1 && loanTypes[0] === 'interest';

    const canShowSamplePlanModalContent = Boolean(
      installmentPlans?.length &&
        fullPrice &&
        (hasZeroInterestLoanType ||
          onlyHasInterestLoanType ||
          isInAdaptiveRangeWithoutZeroInterest),
    );

    setEligible(eligible);
    setFullPrice(fullPrice);

    return {
      ...baseMeta,
      canShowSamplePlanModalContent,
      financingTermForBanner,
      hasZeroInterestLoanType,
      isEligibleForPrequalification,
      isInAdaptiveRangeWithoutZeroInterest,
      installmentPlans,
      loanTypes,
      numberOfPaymentTerms,
      pricePerTerm,
      variantAvailable,
    };
  }, [isLegacyBanner, metaType, parsedShopifyMeta, variantId]);

  const bannerContent: InstallmentsBannerContent = useMemo(() => {
    if (!canShowSamplePlanModalContent) {
      return 'pay_in_4';
    }

    return isInAdaptiveRangeWithoutZeroInterest
      ? 'pay_in_4_or_as_low_as'
      : 'as_low_as';
  }, [canShowSamplePlanModalContent, isInAdaptiveRangeWithoutZeroInterest]);

  const samplePlans = useMemo(
    () => getFormattedSamplePlans({installmentPlans, fullPrice}),
    [installmentPlans, fullPrice],
  );

  const modalVariant = useMemo(() => {
    let variant: InstallmentsModalVariant = 'splitPay';
    if (isEligibleForPrequalification) {
      variant = 'prequal';
    } else if (canShowSamplePlanModalContent) {
      variant = 'samplePlans';
    }

    return variant;
  }, [canShowSamplePlanModalContent, isEligibleForPrequalification]);

  const modalType = useMemo(() => {
    switch (modalVariant) {
      case 'prequal':
        return getPrequalModalType({
          samplePlans,
          loanTypes,
          eligible,
        });
      case 'samplePlans':
        return getSamplePlansModalType(samplePlans);
      default:
        return getSplitPayModalType({
          eligible,
          loanTypes,
        });
    }
  }, [eligible, loanTypes, modalVariant, samplePlans]);

  const priceEligible = useCallback(
    (price: number) => {
      if (!minPrice || !maxPrice) return false;
      const minPriceNumber = convertPriceToNumber(minPrice);
      const maxPriceNumber = convertPriceToNumber(maxPrice);
      return price >= minPriceNumber && price <= maxPriceNumber;
    },
    [minPrice, maxPrice],
  );

  // Cart observer setup
  useCartObserver({
    numberOfPaymentTerms,
    onPriceChange: (newPrice: string) => {
      setFullPrice(newPrice);
      setEligible(priceEligible(convertPriceToNumber(newPrice)));
    },
  });

  // Track banner impressions
  useEffect(() => {
    if (dataLoaded) {
      trackInstallmentsBannerImpression({
        bannerContent,
        bannerTemplateCodeSignature: isLegacyBanner
          ? 'customized_by_merchant'
          : 'standard',
        eligible,
        hasPrequalLink: isEligibleForPrequalification,
        origin: metaType,
        price: fullPrice,
        variantId,
      });
      trackElementImpression({
        elementType: metaType,
        elementName,
      });
    }
  }, [
    bannerContent,
    dataLoaded,
    elementName,
    eligible,
    fullPrice,
    isEligibleForPrequalification,
    isLegacyBanner,
    metaType,
    trackElementImpression,
    trackInstallmentsBannerImpression,
    variantId,
  ]);

  // Once the banner is loaded, we can assign the cart details
  useEffect(() => {
    if (metaType !== 'cart' || !canShowSamplePlanModalContent) {
      return;
    }

    const generateCartDetails = () => {
      if (hostname) {
        return getCart();
      }
    };

    async function assignCartDetails() {
      setCartDetails(await generateCartDetails());
    }

    assignCartDetails();
  }, [canShowSamplePlanModalContent, getCart, hostname, metaType]);

  // Update the banner style for checkout
  useEffect(() => {
    if (metaType === 'checkout') {
      const container = element?.shadowRoot?.querySelector(
        '#shopify-installments',
      );
      container?.classList.add('inline');
    }
  }, [element?.shadowRoot, metaType]);

  useEffect(() => {
    const productForm = element?.closest('form');

    const productQuantity =
      metaType === 'product'
        ? getProductQuantityFromDOM(productForm)
        : undefined;

    const newVariantInfo: VariantModalDetails | undefined = productQuantity
      ? {
          available: variantAvailable,
          idQuantityMapping: `${variantId}:${productQuantity}`,
        }
      : undefined;

    setVariantInfo(newVariantInfo);
  }, [element, metaType, variantAvailable, variantId]);

  useEffect(() => {
    if (!modalOpen) return;

    const newModalToken = uuidv4();
    setModalToken(newModalToken);
  }, [modalOpen]);

  // Modal tracking effect
  useEffect(() => {
    if (!modalOpen) return;

    const spiPlanDetails =
      modalVariant === 'splitPay' ? '[]' : JSON.stringify(samplePlans);

    trackModalOpened({
      cartPermalink: modalVariant === 'splitPay' ? undefined : cartPermalink,
      eligibleSpiPlanType: modalType,
      modalToken,
      origin: metaType,
      price: fullPrice,
      spiPlanDetails,
      variantId,
    });

    trackElementImpression({
      elementType: metaType,
      elementName: 'shopify-installments-modal',
    });

    if (isEligibleForPrequalification) {
      trackInstallmentsBannerPrequalInteraction({
        bannerContent,
        eligible,
        origin: metaType,
        prequalLinkClicked: true,
        price: fullPrice,
      });

      trackInstallmentsPrequalPopupPageImpression({
        sellerId: getSellerIdInNumber(sellerId),
        pageType: 'prequal_intro_page_loaded',
      });
    }
  }, [
    bannerContent,
    cartPermalink,
    eligible,
    fullPrice,
    isEligibleForPrequalification,
    metaType,
    modalOpen,
    modalToken,
    modalType,
    modalVariant,
    samplePlans,
    sellerId,
    trackElementImpression,
    trackInstallmentsBannerPrequalInteraction,
    trackInstallmentsPrequalPopupPageImpression,
    trackModalOpened,
    variantId,
  ]);

  useEffect(() => {
    const productForm = element?.closest('form');
    if (!productForm) return;

    const onProductFormChange = (attempts = 0) => {
      if (attempts > 4) return;

      const variantIdElement = (productForm.querySelector(
        'select[name^="id"]',
      ) ||
        productForm.querySelector('[name^="id"]')) as HTMLInputElement | null;

      const params = new isoWindow.URL(isoWindow.location.href).searchParams;
      const variantParam = params.get('variant');

      let newVariantId;

      if (variantIdElement) {
        newVariantId = Number(variantIdElement.value);
      }

      if (!variantIdElement && !isNaN(Number(variantParam))) {
        newVariantId = Number(variantParam);
      }

      if (!newVariantId) return;

      if (variantId === newVariantId) {
        setTimeout(() => onProductFormChange(attempts + 1), 100);
      } else {
        setVariantId(newVariantId);
      }
    };

    const handleChange = () => onProductFormChange();
    productForm.addEventListener('change', handleChange);

    return () => {
      productForm.removeEventListener('change', handleChange);
    };
  }, [element, variantId]);

  // Auth token fetch effect
  useEffect(() => {
    if (isLegacyBanner || metaType !== 'cart' || !hostname) {
      return;
    }

    const controller = new AbortController();
    let isUnmounted = false;

    const getToken = async () => {
      const newToken = await fetchAuthToken({
        hostname,
        instanceId,
        notify,
        signal: controller.signal,
      });

      if (!isUnmounted && newToken) {
        setAuthorizationToken(newToken);
      }
    };

    getToken();

    return () => {
      isUnmounted = true;
      controller.abort();
    };
  }, [hostname, instanceId, notify, metaType, isLegacyBanner]);

  // Generate the checkout URL
  useEffect(() => {
    // Don't generate the checkout URL for checkout banner or split pay modal
    if (metaType === 'checkout' || modalVariant === 'splitPay') {
      setCheckoutUrl(null);
      return;
    }

    async function generateUrl() {
      let checkoutResponse: Response;
      try {
        // Handle PDP variant-based checkout
        if (metaType === 'product') {
          if (variantInfo) {
            const url = constructLink({
              variants: variantInfo.idQuantityMapping,
              paymentOption: 'shop_pay_installments',
              source: 'installments_modal',
            });
            setCheckoutUrl(url === '#' ? null : url);
            return;
          } else {
            setCheckoutUrl(null);
            return;
          }
        }

        if (!authorizationToken || !cartDetails) {
          setCheckoutUrl(null);
          return;
        }

        // Handle cart-based checkout
        const cartData = cartDetails;

        const hasSubscriptions = cartData.items.some(
          (item) =>
            item.selling_plan_allocation !== undefined &&
            item.selling_plan_allocation.selling_plan !== undefined &&
            !item.selling_plan_allocation.selling_plan.fixed_selling_plan,
        );

        leaveBreadcrumb(
          'Getting checkout config',
          {
            hostname,
          },
          'log',
        );
        checkoutResponse = await fetch(
          `https://${hostname}/wallets/checkouts.json`,
          {
            method: 'POST',
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
              authorization: `Basic ${authorizationToken}`,
            },
            body: JSON.stringify({
              checkout: {
                /* eslint-disable @typescript-eslint/naming-convention */
                cart_token: cartData.token,
                presentment_currency: cartData.currency,
                has_selling_plans: hasSubscriptions,
                secret: true,
                wallet_name: 'ShopifyPay',
                page_type: 'cart_page',
                /* eslint-enable @typescript-eslint/naming-convention */
              },
            }),
          },
        );
        if (!checkoutResponse.ok) {
          throw new Error(`HTTP error! status: ${checkoutResponse.status}`);
        }
      } catch (error) {
        notify(
          new AbstractShopJSError(
            `Error fetching transaction url: ${error}`,
            'FetchTransactionUrlError',
            instanceId,
          ),
        );
        setCheckoutUrl(null);
        return;
      }

      try {
        const {checkout} = await checkoutResponse.json();
        const transactionUrl =
          checkout?.shop_pay_configuration?.transaction_url;

        if (!transactionUrl) {
          setCheckoutUrl(null);
          return;
        }

        const redirectUrl = new isoWindow.URL(transactionUrl);
        redirectUrl.searchParams.set('payment_option', 'shop_pay_installments');
        redirectUrl.searchParams.set('source', 'installments_modal');
        setCheckoutUrl(redirectUrl.toString());
      } catch (error) {
        notify(
          new AbstractShopJSError(
            `Error parsing transaction url: ${error}`,
            'ParseTransactionUrlError',
            instanceId,
          ),
        );
        setCheckoutUrl(null);
      }
    }

    generateUrl();
  }, [
    metaType,
    modalVariant,
    variantInfo,
    authorizationToken,
    cartDetails,
    hostname,
    instanceId,
    notify,
    leaveBreadcrumb,
  ]);

  // Update the checkout URL with modal token
  useEffect(() => {
    if (!checkoutUrl || !modalToken) return;

    try {
      const url = new isoWindow.URL(checkoutUrl);
      url.searchParams.set('source_token', modalToken);
      setCheckoutUrl(url.toString());
    } catch (err) {
      notify(
        new AbstractShopJSError(
          `Error parsing checkout url: ${err}`,
          'CheckoutUrlParseError',
        ),
        {
          metadata: {
            installments: {
              checkoutUrl,
            },
          },
        },
      );
    }
  }, [modalToken, checkoutUrl, notify]);

  const contextValue = useMemo(
    () => ({
      backgroundColor,
      bannerContent,
      canShowSamplePlanModalContent,
      cartPermalink,
      checkoutUrl,
      dataLoaded,
      eligible,
      financingTermForBanner,
      fullPrice,
      hasZeroInterestLoanType,
      installmentPlans,
      installmentsBuyerPrequalificationEnabled,
      isEligibleForPrequalification,
      isInAdaptiveRangeWithoutZeroInterest,
      isLegacyBanner,
      loanTypes,
      maxPrice,
      metaType,
      minIneligibleMessageType,
      minPrice,
      modalOpen,
      modalToken,
      modalVariant,
      numberOfPaymentTerms,
      pricePerTerm,
      samplePlans,
      sellerId,
      setModalOpen,
      variantAvailable,
      variantId,
      variantInfo,
    }),
    [
      backgroundColor,
      bannerContent,
      canShowSamplePlanModalContent,
      cartPermalink,
      checkoutUrl,
      dataLoaded,
      eligible,
      financingTermForBanner,
      fullPrice,
      hasZeroInterestLoanType,
      installmentPlans,
      installmentsBuyerPrequalificationEnabled,
      isEligibleForPrequalification,
      isInAdaptiveRangeWithoutZeroInterest,
      isLegacyBanner,
      loanTypes,
      maxPrice,
      metaType,
      minIneligibleMessageType,
      minPrice,
      modalOpen,
      modalToken,
      modalVariant,
      numberOfPaymentTerms,
      pricePerTerm,
      samplePlans,
      sellerId,
      variantAvailable,
      variantId,
      variantInfo,
    ],
  );

  return (
    <InstallmentsContext.Provider value={contextValue}>
      {children}
    </InstallmentsContext.Provider>
  );
};
